@import "../../styles/mixins";

.container {
  width: 100vw;
  height: 100dvh;
  background: #022222;

  display: grid;
  place-items: center;

  .loading {
    text-align: center;

    h4 {
      font-family: "Barlow Condensed", sans-serif;
    }

    .outerBox {
      width: 25vw;
      height: 4.5rem;
      border: 3px solid #000;
      margin-top: 1rem;
      border-radius: 1rem;
      padding: 2px;
      background-color: #0000001f;

      @include xs {
        width: 50vw;
      }

      @include xxs {
        width: 75vw;
      }
    }

    .innerBox {
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #ffd739, #e3704a);
      border-radius: calc(1rem - 4px);
    }
  }
}
