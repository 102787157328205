@import "../../styles/mixins";

.main {
  background-color: #fff;
  overflow: hidden;

  .innerContainer {
    min-height: 83dvh;
    width: 100%;
    background: linear-gradient(
      rgba(202, 255, 96, 0) 0%,
      rgba(202, 255, 96, 0) 30%,
      rgba(202, 255, 96, 0.2) 100%
    );
    padding: 0 5vw;
    position: relative;
    overflow: hidden;

    @include noUserSelect;

    @include xs {
      min-height: 62dvh;
    }

    .bgLeft {
      position: absolute;
      width: 30vw;
      height: calc(30vw / (538 / 370));
      bottom: 0;
      left: 0;

      @include sm {
        width: 42vw;
        height: calc(42vw / (538 / 370));
        bottom: -1px;
      }
    }

    .bgRight {
      position: absolute;
      width: 30vw;
      height: calc(30vw / (479 / 509));
      bottom: 0;
      right: 0;

      @include sm {
        width: 42vw;
        height: calc(42vw / (479 / 509));
        bottom: -1px;
      }
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 3dvh;

      .social {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.4rem;

        .socialIcon {
          width: 4.5rem;
          height: calc(4.5rem / (42 / 50));
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            filter: brightness(0.9);
          }

          @include xs {
            width: 3.6rem;
            height: calc(3.6rem / (42 / 50));
          }
        }
      }

      .logo {
        width: 15rem;
        height: calc(15rem / (200 / 56));
        flex: 1;
      }

      .right {
        flex: 1;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;

  h1 {
    color: #000;
    text-transform: uppercase;
    font-family: "Teko", sans-serif;
    font-weight: 500;
    font-size: 7rem;

    @include xs {
      font-size: 4rem;
    }
  }

  .rectangles {
    width: 4rem;
    height: 4rem;

    @include xs {
      width: 3rem;
      height: 3rem;
    }

    &:last-of-type {
      transform: rotate(180deg);
    }
  }
}

.grid {
  margin-top: 5dvh;
  display: grid;
  grid-template-columns: repeat(3, 1fr); //when we have more games change to 4
  gap: 2rem;
  position: relative;
  z-index: 10;
  place-items: center;
  align-items: flex-start;
  width: 75vw;
  max-width: 120rem;
  margin: 5dvh auto;

  @include md {
    width: 80vw;
  }

  @include xs {
    width: 90vw;
    grid-template-columns: repeat(2, 1fr); //keep 2
    gap: 1.6rem;
  }

  @include tiny {
    width: 80vw;
    grid-template-columns: repeat(1, 1fr);
  }
}
