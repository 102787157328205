@import "../../styles/mixins";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  bottom: 0;
  width: 100%;
  height: 17dvh;
  overflow: hidden;

  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;

  @include noUserSelect;

  @include xs {
    height: 38dvh;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: 0 5vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    justify-content: space-between;
    flex-grow: 1;

    @include xs {
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
    }

    .logo {
      width: 15rem;
      height: calc(15rem / (200 / 56));
    }

    .navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5rem;
      align-self: center;
      flex-grow: 1;

      @include xs {
        flex-direction: column;
        flex-grow: unset;
        gap: 2rem;
      }

      a {
        color: #8a94a6;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 2.2rem;
        text-align: center;

        transition: all 0.3s;
        &:hover {
          color: #626976;
        }
      }
    }

    .social {
      width: 15rem;

      @include xs {
        display: none;
      }
    }
  }

  p {
    color: #8a94a6;
    padding: 1dvh 0;
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    width: 100vw;

    @include xs {
      padding: 2dvh 0;
    }
  }
}
