@import "../../styles/mixins";

.box {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #c5c5c5;
  transition: all 0.3s;
  border: 3px solid #000;

  &:hover {
    background-color: darken($color: lightseagreen, $amount: 8);

    .inner {
      background-color: darken($color: white, $amount: 8);
    }

    .soon {
      background-color: lighten($color: #e5e5e5, $amount: 8);
    }
  }

  .inner {
    height: calc(100% - 1.6rem);
    background-color: white;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 1.2rem;
    transition: all 0.3s;

    img {
      width: 100%;
      aspect-ratio: 1/1;
      border-radius: 1rem;
    }

    .downloads {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      height: 7rem;
      margin-bottom: 1.6rem;

      @include xs {
        flex-direction: column;
        height: 14rem;
        margin-bottom: 2rem;
      }

      .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include xs {
          height: calc(50% - 0.6rem);
        }
      }

      .google {
        background-color: #fff;
        border-radius: 1rem;
        border: 2px solid #000;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
      }

      .apple {
        background-color: #000;
        border-radius: 1rem;
        border: 2px solid #a6a6a6;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0.2rem;
      }

      .download {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .soon {
      width: 100%;
      height: 7rem;
      background-color: #e5e5e5;
      display: grid;
      place-items: center;
      border-radius: 1rem;
      transition: all 0.3s;
      margin-bottom: 1.6rem;

      @include xxs {
        height: 5rem;
      }

      h3 {
        color: #00000062;
        font-family: "Teko";
        font-weight: 500;
        font-size: 2.4rem;

        @include xxs {
          font-size: 2.2rem;
        }
      }
    }

    .now {
      width: 100%;
      height: 7rem;
      background-color: #c5c5c5;
      border: 2px solid #000;
      border-radius: 1rem;
      transition: all 0.3s;
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 1rem;
      position: relative;
      z-index: 10;

      @include xxs {
        height: 6rem;
      }

      .inner {
        background-color: #fff;
        display: grid;
        place-items: center;
        width: 100%;
        height: calc(100% - 0.8rem);
        border-radius: 0 0 0.6rem 0.6rem;

        h3 {
          color: #000;
          font-family: "Teko";
          font-weight: 600;
          font-size: 2.4rem;

          @include xxs {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}
